<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row class="mb-4">
        <b-col>
          <b-form-group
            :description="$t('calendarModule.slotForm.descriptions.type')"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.type") }}
              <span class="text-danger">*</span>
            </template>

            <b-form-select
              :options="typeOptions"
              v-model="chosenType"
              :disabled="typeOptions.length === 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-form-group
            :description="$t('calendarModule.slotForm.descriptions.fromDate')"
            :state="!$v.fromDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.fromDate") }}
              <span class="text-danger">*</span>
            </template>

            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.fromDate.$model"
              @change="fromDateChanged"
            ></date-picker>
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.date")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :description="$t('calendarModule.slotForm.descriptions.toDate')"
            :state="!$v.toDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slotForm.labels.toDate") }}
              <span class="text-danger">*</span>
            </template>

            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="$v.toDate.$model"
              @change="toDateChanged"
            ></date-picker>
            <b-form-invalid-feedback>{{
              $t("calendarModule.slotForm.errors.date")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="!isMobileScreenSize && !isTabletScreenSize">
        <b-row v-for="(chosenSlot, sIdx) in chosenSlots" :key="sIdx">
          <b-col>
            <b-form-group
              :description="$t('calendarModule.slotForm.descriptions.date')"
              :state="!$v.editSlotDate.$error"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.date") }}
                <span class="text-danger">*</span>
              </template>
              <date-picker
                class="w-100"
                format="DD.MM.YYYY"
                input-class="form-control pac-target-input"
                v-model="chosenSlot.date"
                :disabled="true"
              ></date-picker>
              <b-form-invalid-feedback>{{
                $t("calendarModule.slotForm.errors.date")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="$t('calendarModule.slotForm.descriptions.time')"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.time") }}
                <span class="text-danger">*</span>
              </template>
              <b-form-timepicker
                v-model="chosenSlot.time"
                :minutes-step="5"
                :hour12="false"
                @input="refreshToDate(sIdx)"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="
                $t('calendarModule.slotForm.descriptions.slotLengthCounter')
              "
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.slotLengthCounter") }}
                <span class="text-danger">*</span>
              </template>
              <div class="mt-2">
                <img
                  class="feathericons-minus-circle p-3 mr-2 mb-2 bg-danger btn"
                  @click="subtractSlotCounter(sIdx)"
                />
                <span class="font-size-middle">{{ chosenSlot.slotCount }}</span>
                <img
                  class="feathericons-plus-circle p-3 ml-2 mb-2 bg-success btn"
                  @click="addSlotCounter(sIdx)"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :description="$t('calendarModule.slotForm.descriptions.endTime')"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.endTime") }}
              </template>
              <b-form-timepicker
                v-model="chosenSlot.endTime"
                :minutes-step="5"
                :hour12="false"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <div v-if="chosenSlotsWithUniqueSlots[sIdx]">
              <strong v-if="chosenSlotsWithUniqueSlots[sIdx][0]">{{
                $t("calendarModule.slotForm.massCreation.availableSlots")
              }}</strong>
              <b-badge
                v-for="uniqueSlot of chosenSlotsWithUniqueSlots[sIdx]"
                :key="'slotExampleTime' + uniqueSlot.time"
                class="cm-badge-list-element mb-1"
                variant="light"
              >
                {{ uniqueSlot.time }} - {{ uniqueSlot.endTime }}
              </b-badge>
            </div>
          </b-col>
          <b-col class="col-12 mb-3">
            <div
              v-if="chosenSlotsWithUniqueSlots[sIdx] && chosenType === 'gom'"
            >
              <strong
                ><b-img class="feathericons-alert-circle bg-dark p-2 pb-3" />
                {{
                  $t("calendarModule.slotForm.massCreation.followupWarning", {
                    date: moment(chosenSlot.date)
                      .add(3, "days")
                      .format("DD.MM.YYYY")
                  })
                }}</strong
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row
          v-for="(chosenSlot, sIdx) in chosenSlots"
          :key="sIdx"
          :style="isMobileScreenSize ? 'width: 110%;' : ''"
        >
          <div style="width: 100%;" class="border-top border-grey" />
          <b-col class="mt-4">
            <b-form-group
              :description="$t('calendarModule.slotForm.descriptions.date')"
              :state="!$v.editSlotDate.$error"
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.date") }}
                <span class="text-danger">*</span>
              </template>
              <date-picker
                class="w-100"
                format="DD.MM.YYYY"
                input-class="form-control pac-target-input"
                v-model="chosenSlot.date"
                :disabled="true"
              ></date-picker>
              <b-form-invalid-feedback>{{
                $t("calendarModule.slotForm.errors.date")
              }}</b-form-invalid-feedback>
            </b-form-group>
            <b-row>
              <b-col class="col-6">
                <b-form-group
                  :description="$t('calendarModule.slotForm.descriptions.time')"
                >
                  <template v-slot:label>
                    {{ $t("calendarModule.slotForm.labels.time") }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-timepicker
                    v-model="chosenSlot.time"
                    :minutes-step="5"
                    :hour12="false"
                    @input="refreshToDate(sIdx)"
                  />
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group
                  :description="
                    $t('calendarModule.slotForm.descriptions.endTime')
                  "
                >
                  <template v-slot:label>
                    {{ $t("calendarModule.slotForm.labels.endTime") }}
                  </template>
                  <b-form-timepicker
                    v-model="chosenSlot.endTime"
                    :minutes-step="5"
                    :hour12="false"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              :description="
                $t('calendarModule.slotForm.descriptions.slotLengthCounter')
              "
            >
              <template v-slot:label>
                {{ $t("calendarModule.slotForm.labels.slotLengthCounter") }}
                <span class="text-danger">*</span>
              </template>
              <div class="mt-2">
                <img
                  class="feathericons-minus-circle p-3 mr-2 mb-2 bg-danger btn"
                  @click="subtractSlotCounter(sIdx)"
                />
                <span class="font-size-middle">{{ chosenSlot.slotCount }}</span>
                <img
                  class="feathericons-plus-circle p-3 ml-2 mb-2 bg-success btn"
                  @click="addSlotCounter(sIdx)"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <div v-if="chosenSlotsWithUniqueSlots[sIdx]">
              <strong v-if="chosenSlotsWithUniqueSlots[sIdx][0]">{{
                $t("calendarModule.slotForm.massCreation.availableSlots")
              }}</strong>
              <b-badge
                v-for="uniqueSlot of chosenSlotsWithUniqueSlots[sIdx]"
                :key="'slotExampleTime' + uniqueSlot.time"
                class="cm-badge-list-element mb-1"
                variant="light"
              >
                {{ uniqueSlot.time }} - {{ uniqueSlot.endTime }}
              </b-badge>
            </div>
          </b-col>
          <b-col class="col-12 mb-3">
            <div
              v-if="chosenSlotsWithUniqueSlots[sIdx] && chosenType === 'gom'"
            >
              <strong
                ><b-img class="feathericons-alert-circle bg-dark p-2 pb-3" />
                {{
                  $t("calendarModule.slotForm.massCreation.followupWarning", {
                    date: moment(chosenSlot.date)
                      .add(3, "days")
                      .format("DD.MM.YYYY")
                  })
                }}</strong
              >
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-4">
        <b-col>
          <b-button
            class="btn btn-primary"
            size="xs"
            @click="checkForFailedBookings"
          >
            {{ $t("misc.save") }}
          </b-button>
          <b-button
            class="btn btn-outline-primary ml-2"
            size="xs"
            @click="slotsChanged"
          >
            {{ $t("misc.abort") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Modal Booking Conflict-->
    <b-modal
      :title="$t('calendarModule.slotForm.bookingConflict.title')"
      ref="failedBookingModal"
      size="xl"
      hide-footer
      onclose="closeCreateSoulLetterModal()"
    >
      <b-row class="mb-4">
        <b-form-group class="col-md-12">
          <label>{{
            $t("calendarModule.slotForm.bookingConflict.description")
          }}</label>
        </b-form-group>
      </b-row>
      <b-row>
        <b-col>
          <div class="table-responsive mt-2">
            <perfect-scrollbar>
              <table id="style-3" class="table style-3  table-hover">
                <thead>
                  <tr>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.date") }}
                    </th>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.time") }}
                    </th>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.endTime") }}
                    </th>
                    <th>
                      {{ $t("calendarModule.slotForm.labels.booked") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(failedBooking, fIdx) in failedBookings"
                    :key="fIdx"
                  >
                    <td>
                      <span>{{
                        moment(failedBooking.date).format("YYYY-MM-DD")
                      }}</span>
                    </td>
                    <td>
                      <span>{{ failedBooking.time }}</span>
                    </td>
                    <td>
                      <span>{{ failedBooking.endTime }}</span>
                    </td>
                    <td v-if="failedBooking.free">
                      <b-badge class="bg-success font-size-2 shadow-none">
                        <span>{{
                          $t("calendarModule.slotForm.labels.free")
                        }}</span>
                      </b-badge>
                    </td>
                    <td v-else>
                      <b-badge class="bg-warning font-size-2 shadow-none">
                        <span>{{
                          $t("calendarModule.slotForm.labels.notFree")
                        }}</span>
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </b-col>
      </b-row>
      <button @click="confirmFailedBooking()" class="btn btn-success mb-2 mr-2">
        {{ $t("misc.confirm") }}
      </button>
      <button
        @click="closeFailedBookingModal()"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("misc.abort") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import * as moment from "moment";
import { validationMixin } from "vuelidate";
import { v4 as uuidv4 } from "uuid";
import { addNotification } from "../../../utils/notificationHandler";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";

import {
  required,
  integer,
  minValue,
  maxValue,
  requiredIf
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as userApi from "../../../store/modules/users/users.api";
import {
  convertToCalendarTime,
  convertToLocal
} from "../../../utils/timezoneHelper";
import screenSizeMixin from "@/mixins/screenSize";
import { EConversationTypes } from "@/store/modules/conversations/EConversationTypes";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import config from "@/config/config";

export default {
  name: "SlotForm",
  props: [
    "theCalendar",
    "theSlot",
    "forDate",
    "withSlotActions",
    "withSlotType",
    "forUserId"
  ],
  components: {
    DatePicker
  },
  mixins: [validationMixin, screenSizeMixin],
  validations: {
    fromDate: {
      required
    },
    toDate: {
      required
    },
    editSlotDate: {
      required
    },
    slot: {
      metadata: {
        /* slotType: {
          required
        },
        followupType: {
          required: requiredIf(function() {
            return (
              this.slot.metadata.slotType &&
              this.slot.metadata.slotType.startsWith("vaccination")
            );
          })
        },
        extraDescription: {
          minLength: minLength(0),
          maxLength: maxLength(5000)
        }*/
      }
    },
    massCreation: {
      amountPerTimeFrame: {
        integer,
        required: requiredIf(function() {
          return this.massCreation;
        }),
        minValue: minValue(1),
        maxValue: maxValue(50)
      },
      repeatMinuteGap: {
        integer,
        required: requiredIf(function() {
          return this.massCreation;
        }),
        minValue: minValue(0),
        maxValue: maxValue(120)
      }
    }
  },
  data() {
    return {
      moment: moment,
      isBookingConflict: false,
      isFailedBookingsConfirmed: false,
      failedBookings: [],
      chosenType: EConversationTypes.ONE_ON_ONE,
      chosenSlots: [],
      chosenSlotsWithUniqueSlots: [],
      newBookingArray: [],
      fromDate: null, //moment().toDate(),
      toDate: null, //moment().toDate(),
      slotLength: 90,
      slotCounter: 0,
      latestBookingTime: 5,
      currentUser: null,
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendar: null,
      slot: null,
      baseSlot: {
        time: "12:00",
        endTime: "12:15", // moment(this.baseSlot.time, "HH:mm").add(this.slotLength, "minutes").format("HH:mm"),//this.slotLength,//"12:15",
        date: this.forDate,
        endDate: this.forDate,
        free: true
        /* metadata: {
               slotType: "",
           followupType: "soonestPossible",
           extraDescription: ""

          } */
      },
      massCreation: null,
      slotsToSave: [],
      editSlotDate: moment().toDate(),
      sendRescheduleNotification: false,
      dummyCalendarData: {
        _id: "be01508b-18b4-4d30-8412-00d08ceec90f",
        requiresPermission: false,
        defaultSlotsDaysBefore: 14,
        defaultSlotsExcludedDates: [],
        name: "Dummy Calendar",
        metadata: {
          color: "#73D8FF",
          aboveAddressText: "Anzeige Infotext Oberhalb",
          addressText: null,
          belowAddressText: "Anzeige Infotext Unterhalb",
          customFieldPermissions: null,
          isInactive: false
        },
        ownerId: "5af2a1f9-0cbe-4158-b53f-ae36b6c4ec7c",
        creationTimeStamp: "2021-06-23 06:13:16.839Z",
        updateTimeStamp: "2021-06-23 06:13:16.839Z",
        _v: 0
      }
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    typeOptions() {
      return Object.values(EConversationTypes)
        .filter(type => {
          return (
            type !== EConversationTypes.GOM ||
            this.getCurrentUser.role === EUserRoles.STUDENT
          );
        })
        .map(type => {
          return {
            text: this.$t(`calendarModule.slotForm.typeOptions.${type}`),
            value: type
          };
        });
    },
    days() {
      const days = [];
      const fromDateMoment = moment(this.fromDate);
      const toDateMoment = moment(this.toDate);
      if (fromDateMoment && toDateMoment) {
        //if (this.fromDate && this.toDate) {
        const end = toDateMoment.clone().endOf("day");
        const iDate = fromDateMoment.clone();
        while (iDate.isBefore(end)) {
          days.push(iDate.clone());
          iDate.add(1, "day");
        }
      }
      return days;
    },
    forDateFormatted() {
      return moment(this.forDate).format("DD.MM.YYYY");
    },
    massCreationSlots() {
      /* if (!this.inputsValid) {
        return null;
      }*/
      if (this.massCreation) {
        const massUuid = uuidv4();
        const allSlots = [];
        const uniqueSlots = [];

        const baseTime = moment(this.slot.time, "HH:mm");
        const baseEndTime = moment(this.slot.endTime, "HH:mm");
        const untilTime = moment(this.massCreation.repeatUntil, "HH:mm");

        // const slotLength = Math.round(
        //  moment.duration(baseEndTime.diff(baseTime)).asMinutes()
        // );

        const slotLength = this.chosenType === EConversationTypes.GOM
            ? config.gomDuration
            : this.slotLength;
        const diffMinutes =
          parseInt(slotLength, 10) +
          parseInt(this.massCreation.repeatMinuteGap, 10);
        while (baseEndTime.isSameOrBefore(untilTime)) {
          for (let i = 0; i < this.massCreation.amountPerTimeFrame; i++) {
            allSlots.push({
              ...this.slot,
              metadata: {
                ...this.slot.metadata,
                massUuid
              },
              time: baseTime.format("HH:mm"),
              endTime: baseEndTime.format("HH:mm")
            });
          }
          uniqueSlots.push({
            ...this.slot,
            metadata: {
              ...this.slot.metadata,
              massUuid
            },
            time: baseTime.format("HH:mm"),
            endTime: baseEndTime.format("HH:mm")
          });
          baseTime.add(diffMinutes, "minutes");
          baseEndTime.add(diffMinutes, "minutes");
        }
        return { allSlots, uniqueSlots };
      }
      return null;
    }
  },
  methods: {
    createMassCreationSlots(sIdx) {
      if (this.massCreation) {
        const massUuid = uuidv4();
        const allSlots = [];
        const uniqueSlots = [];

        const baseTime = moment(this.chosenSlots[sIdx].time, "HH:mm");
        const baseEndTime = moment(this.chosenSlots[sIdx].time, "HH:mm").add(
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration
            : this.slotLength,
          "minutes"
        );
        const untilTime = moment(this.massCreation.repeatUntil, "HH:mm");

        const slotLength =
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration
            : this.slotLength;
        const diffMinutes =
          parseInt(slotLength, 10) +
          parseInt(this.massCreation.repeatMinuteGap, 10);
        while (baseEndTime.isSameOrBefore(untilTime)) {
          for (let i = 0; i < this.massCreation.amountPerTimeFrame; i++) {
            allSlots.push({
              ...this.chosenSlots[sIdx],
              metadata: {
                // ...this.slot.metadata,
                massUuid
              },
              time: baseTime.format("HH:mm"),
              endTime: baseEndTime.format("HH:mm")
            });
          }
          uniqueSlots.push({
            ...this.chosenSlots[sIdx],
            metadata: {
              // ...this.slot.metadata,
              massUuid
            },
            time: baseTime.format("HH:mm"),
            endTime: baseEndTime.format("HH:mm")
          });
          baseTime.add(diffMinutes, "minutes");
          baseEndTime.add(diffMinutes, "minutes");
        }
        return { allSlots, uniqueSlots };
      }
      return null;
    },
    checkForFailedBookings() {
      this.failedBookings = [];
      this.newBookingArray = JSON.parse(
        JSON.stringify(this.chosenSlotsWithUniqueSlots)
      );
      this.isBookingConflict = false;
      let slots = null;
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          slots = res.data.data;
          for (let u = 0; u < this.chosenSlotsWithUniqueSlots.length; u++) {
            if (!this.chosenSlotsWithUniqueSlots[u]) {
              continue;
            }
            if (this.chosenSlotsWithUniqueSlots[u] === []) {
              continue;
            }
            for (
              let i = 0;
              i < this.chosenSlotsWithUniqueSlots[u].length;
              i++
            ) {
              const currentChosenSlotTmp = this.chosenSlotsWithUniqueSlots[u];
              const currentChosenSlot = currentChosenSlotTmp[i];
              for (let j = 0; j < slots.length; j++) {
                const convertedUtc = convertToLocal(
                  slots[j].date,
                  slots[j].endDate,
                  slots[j].time,
                  slots[j].endTime
                );
                const formattedSlot = {
                  time: convertedUtc.time,
                  endTime: convertedUtc.endTime,
                  date: convertedUtc.date,
                  endDate: convertedUtc.endDate,
                  free: slots[j].free,
                  _id: slots[j]._id
                };
                const currentSlot = formattedSlot;
                if (
                  moment(currentSlot.date, "YYYY-MM-DD").isSame(
                    moment(currentChosenSlot.date, "YYYY-MM-DD"),
                    "day"
                  )
                ) {
                  const fromTime = moment(currentChosenSlot.time, "HH:mm");
                  const toTime = moment(currentChosenSlot.endTime, "HH:mm");
                  const time = moment(currentSlot.time, "HH:mm");
                  const endTime = moment(currentSlot.endTime, "HH:mm");
                  if (
                    currentChosenSlot.time === currentSlot.time ||
                    currentChosenSlot.time === currentSlot.endTime ||
                    currentChosenSlot.endTime === currentSlot.time ||
                    currentChosenSlot.endTime === currentSlot.endTime ||
                    (fromTime.diff(time) < 0 &&
                      toTime.diff(endTime) < 0 &&
                      toTime.diff(time) > 0) ||
                    (fromTime.diff(time) > 0 &&
                      fromTime.diff(endTime) < 0 &&
                      toTime.diff(endTime) > 0) ||
                    (fromTime.diff(time) < 0 && toTime.diff(endTime) > 0) ||
                    (fromTime.diff(time) > 0 &&
                      fromTime.diff(endTime) < 0 &&
                      toTime.diff(time) > 0 &&
                      toTime.diff(endTime) < 0)
                  ) {
                    if (!currentSlot.free) {
                      this.newBookingArray[u].splice(i, 1);
                    }
                    const slotDuplicate = this.failedBookings.find(
                      slot => slot._id === currentSlot._id
                    );
                    if (!slotDuplicate) {
                      this.failedBookings.push(currentSlot);
                    }
                    this.isBookingConflict = true;
                  }
                }
              }
            }
          }

          if (this.isBookingConflict) {
            this.openFailedBookingModal();
            // return;
          } else {
            this.saveNewBookings();
          }
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
        });
    },
    saveNewBookings() {
      for (let i = 0; i < this.newBookingArray.length; i++) {
        if (!this.newBookingArray[i]) {
          continue;
        }
        for (let j = 0; j < this.newBookingArray[i].length; j++) {
          const slotFormattedTime = this.newBookingArray[i][j].time;
          const slotFormattedEndTime = this.newBookingArray[i][j].endTime;
          const slotFormattedDate = moment(
            this.newBookingArray[i][j].date,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
          const slotFormattedEndDate = moment(
            this.newBookingArray[i][j].endDate,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
          const convertedUtc = convertToCalendarTime(
            slotFormattedDate,
            slotFormattedEndDate,
            slotFormattedTime,
            slotFormattedEndTime
          );
          const payload = {
            time: convertedUtc.time,
            endTime: convertedUtc.endTime,
            date: convertedUtc.date,
            endDate: convertedUtc.endDate,
            metadata: {
              type: this.chosenType
            }
          };
          this.loading = true;
          this.loadingError = false;
          cmApi.slot
            .createTimeSlot(this.currentUser._id, payload)
            .then(res => {
              // this.saveNextSlot();
              this.slotsChanged();
              //this.$emit("reloadCalendar", res.data);
              this.loading = false;
              this.loadingError = false;
            })
            .catch(err => {
              this.loading = false;
              this.loadingError = true;
            });
        }
      }
    },
    confirmFailedBooking() {
      this.loading = true;
      this.loadingError = false;
      this.isFailedBookingsConfirmed = true;
      for (let i = 0; i < this.failedBookings.length; i++) {
        if (this.failedBookings[i].free) {
          cmApi.slot
            .deleteTimeSlot(this.currentUser._id, this.failedBookings[i]._id);
        }
      }
      setTimeout(() => {
        this.saveNewBookings();
      }, 500);
      this.loading = false;
      this.loadingError = false;
      this.$refs["failedBookingModal"].hide();
    },
    openFailedBookingModal() {
      this.$refs["failedBookingModal"].show();
    },
    closeFailedBookingModal() {
      this.isFailedBookingsConfirmed = false;
      this.$refs["failedBookingModal"].hide();
    },
    fromDateChanged() {
      this.reloadChosenSlotsArray();
    },
    toDateChanged() {
      this.reloadChosenSlotsArray();
    },
    refreshToDate(sIdx) {
      const dayIdxNumber = parseInt(sIdx, 10);
      this.chosenSlots[dayIdxNumber].endTime = moment(
        this.chosenSlots[dayIdxNumber].time,
        "HH:mm"
      )
        .add(
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration * this.chosenSlots[dayIdxNumber].slotCount
            : this.slotLength * this.chosenSlots[dayIdxNumber].slotCount,
          "minutes"
        )
        .format("HH:mm");

      this.toggleMassCreationDaniel(this.chosenSlots[dayIdxNumber]);
      const massCreationSlots = this.createMassCreationSlots(dayIdxNumber);
      this.chosenSlotsWithUniqueSlots[dayIdxNumber] = JSON.parse(
        JSON.stringify(massCreationSlots.uniqueSlots)
      );
    },
    addSlotCounter(sIdx) {
      const dayIdxNumber = parseInt(sIdx, 10);
      this.chosenSlots[dayIdxNumber].slotCount =
        this.chosenSlots[dayIdxNumber].slotCount + 1;
      this.chosenSlots[dayIdxNumber].endTime = moment(
        this.chosenSlots[dayIdxNumber].time,
        "HH:mm"
      )
        .add(
          this.chosenType === EConversationTypes.GOM
            ? config.gomDuration * this.chosenSlots[dayIdxNumber].slotCount
            : this.slotLength * this.chosenSlots[dayIdxNumber].slotCount,
          "minutes"
        )
        .format("HH:mm");
      this.toggleMassCreationDaniel(this.chosenSlots[dayIdxNumber]);
      const massCreationSlots = this.createMassCreationSlots(dayIdxNumber);
      this.chosenSlotsWithUniqueSlots[dayIdxNumber] = JSON.parse(
        JSON.stringify(massCreationSlots.uniqueSlots)
      );
    },
    subtractSlotCounter(sIdx) {
      const dayIdxNumber = parseInt(sIdx, 10);
      if (this.chosenSlots[dayIdxNumber].slotCount > 0) {
        this.chosenSlots[dayIdxNumber].slotCount =
          this.chosenSlots[dayIdxNumber].slotCount - 1;
        this.chosenSlots[dayIdxNumber].endTime = moment(
          this.chosenSlots[dayIdxNumber].endTime,
          "HH:mm"
        )
          .subtract(
            this.chosenType === EConversationTypes.GOM
              ? config.gomDuration
              : this.slotLength,
            "minutes"
          )
          .format("HH:mm");
        this.toggleMassCreationDaniel(this.chosenSlots[dayIdxNumber]);

        const massCreationSlots = this.createMassCreationSlots(dayIdxNumber);
        this.chosenSlotsWithUniqueSlots[dayIdxNumber] = JSON.parse(
          JSON.stringify(massCreationSlots.uniqueSlots)
        );
      }
    },
    toggleMassCreation() {
      if (this.massCreation) {
        this.massCreation = null;
      } else {
        this.massCreation = {
          amountPerTimeFrame: 1,
          repeatUntil: this.slot.endTime,
          repeatMinuteGap: 0
        };
      }
    },
    toggleMassCreationDaniel(currentSlot) {
      this.massCreation = {
        amountPerTimeFrame: 1,
        repeatUntil: currentSlot.endTime,
        repeatMinuteGap: 0
      };
    },
    loadCalendar() {
      if (this.theCalendar && typeof this.theCalendar !== "string") {
        this.calendar = this.theCalendar;
      } else {
        this.calendar = null;
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        addNotification(
          "error",
          this.$t("misc.error"),
          this.$t("calendarModule.errors.inputInvalid")
        );
        return false;
      }
      this.saveNextSlot();
    },
    saveNextSlot() {
      const payload = {
        time: this.slot.time,
        endTime: this.formattedEndTimeForSlot, //this.slot.endTime,
        date: this.slot.date,
        endDate: this.slot.endDate
        // defaultSlot: uuid ??
      };
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .updateTimeSlot(this.currentUser._id, this.slot._id, payload)
        .then(res => {
          this.saveNextSlot();
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });

      this.saveNewBookings();
    },
    slotsChanged() {
      this.$emit("slotsAddedOrSaved");
    },
    reloadChosenSlotsArray() {
      this.chosenSlotsWithUniqueSlots = [];
      this.chosenSlots = [];
      for (let i = 0; i < this.days.length; i++) {
        const date = this.days[i]._d;
        this.chosenSlots.push({
          date: date,
          endDate: date,
          time: "12:00",
          slotCount: 0,
          endTime: "12:00",
          repeatUntil: "12:00"
        });

        this.chosenSlots[i].date = moment(this.chosenSlots[i].date)
          .add(13, "hours")
          .toDate();
        this.chosenSlots[i].endDate = moment(this.chosenSlots[i].endDate)
          .add(13, "hours")
          .toDate();
      }
    },
    initSlotsArray() {
      for (let i = 0; i < this.days.length; i++) {
        const date = this.days[i]._d;
        const chosenSlotsPayload = {
          date: date,
          endDate: date,
          time: "12:00",
          slotCount: 0,
          endTime: "12:00",
          repeatUntil: "12:00"
        };
        //  this.chosenSlots.push(chosenSlotsPayload);

        //  this.chosenSlots[0].date = moment(this.chosenSlots[0].date).subtract(24, "hours").toDate();
        //  this.chosenSlots[0].endDate = moment(this.chosenSlots[0].endDate).subtract(24, "hours").toDate();
      }
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.loading = true;
    this.loadingError = false;
    userApi
      .getUser(this.currentUser._id)
      .then(res => {
        const user = res.data.data;
        this.loading = false;
        this.loadingError = false;
        if (user.companionStudentSettings.slotDuration) {
          this.slotLength = parseInt(
            user.companionStudentSettings.slotDuration,
            10
          );
        }
      })
      .catch(err => {
        this.loading = false;
        this.loadingError = true;
      });

    this.baseSlot.endTime = moment(this.baseSlot.time, "HH:mm")
      .add(this.slotLength, "minutes")
      .format("HH:mm");

    this.loadCalendar();
    this.initSlotsArray();
  }
};
</script>
